import { fetchConfig } from "../helper/fetch-config";
import { publish, subscribe } from '../helper/pubsub';
import { PUB_SUB_EVENTS } from '../helper/constants';

if (!customElements.get('product-form')) {
  customElements.define(
    'product-form',
    class ProductForm extends HTMLElement {
      constructor() {
        super();
        this.form = this.querySelector('form');
        this.form.querySelector('[name=id]').disabled = false;
        this.form.addEventListener('submit', this.onSubmitHandler.bind(this));
        this.addEventListener('change', this.onChangeHandler.bind(this));
        this.cart = document.querySelector('cart-drawer');
        this.submitButton = this.querySelector('[type="submit"]');
        this.stickyATC = this.querySelector('sticky-atc button');
        this.message = this.querySelector('.size_select');
        this.oneSize = this.getAttribute('one_size') || false;

        // Store original button text to restore when available variants are selected
        this.originalButtonText = this.submitButton.querySelector('span')
          ? this.submitButton.querySelector('span').textContent
          : window.variantStrings.addToCart;

        // Store original sticky button text if it exists
        this.originalStickyButtonText =
          this.stickyATC && this.stickyATC.querySelector('span')
            ? this.stickyATC.querySelector('span').textContent
            : window.variantStrings.addToCart;

        if (document.querySelector('cart-drawer')) this.submitButton.setAttribute('aria-haspopup', 'dialog');

        this.hideErrors = this.dataset.hideErrors === 'true';

        // Listen for variant changes to update button state
        this.setupVariantChangeListener();

        // Add listener for the product:updated event from color-swatch.js
        document.addEventListener('product:updated', () => {
          // When product is updated, wait a moment for variant components to initialize
          setTimeout(() => this.updateButtonForVariantAvailability(), 100);
        });

        // TODO -- VARIANT SIZE SELECT BEFORE ATC
        // check if there is a selected value in the size & there is more than 1 size option.
        // if there is not change the ATC text
        this.sizeSelect = this.querySelector('#SizeVariants');
        if (this.sizeSelect) {
          //check if there is a size selected.
          const radios = this.sizeSelect.querySelectorAll('input[type="radio"]');
          let hasValue = false;
          radios.forEach((element) => {
            if (element.checked) hasValue = true;
          });

          if (hasValue) {
            this.message.textContent = '';

            this.submitButton.setAttribute('select-size', 'false');

            if (this.stickyATC) this.stickyATC.removeAttribute('scroll');
          } else {
            //this.message.textContent = window.variantStrings.selectVariant;

            this.submitButton.setAttribute('select-size', 'true');
            if (this.stickyATC) this.stickyATC.setAttribute('scroll', 'true');
          }
        }

        // Check initial variant availability
        this.updateButtonForVariantAvailability();
      }

      setupVariantChangeListener() {
        // Get the product form ID from the form element
        const productFormId = this.form.id;

        // Listen for variant changes using the pubsub system
        subscribe(PUB_SUB_EVENTS.variantChange, (event) => {
          // Only update this form if the event is for this specific product form
          if (!event.data.productFormId || event.data.productFormId === productFormId) {
            this.updateButtonForVariantAvailability(event.data.variant);
          }
        });

        // Also listen for changes to the variant ID input
        const variantInput = this.form.querySelector('[name="id"]');
        if (variantInput) {
          variantInput.addEventListener('change', () => {
            this.updateButtonForVariantAvailability();
          });
        }
      }

      updateButtonForVariantAvailability(variant = null) {
        // Get the current variant ID from the form
        const variantInput = this.form.querySelector('[name="id"]');
        if (!variantInput) return;

        const variantId = variantInput.value;
        if (!variantId) return;

        // Find the variant data
        let currentVariant;
        const variantSelects = document.querySelector('variant-selects, variant-radios');

        // Only proceed if we have a variant component with initialized data
        if (!variantSelects) return;

        if (variant) {
          // If variant was passed directly (from variantChange event)
          currentVariant = variant;
        } else {
          // Don't attempt to get variant data until the component is ready
          if (typeof variantSelects.getVariantData !== 'function') return;

          // Only now try to get the variant data
          const variantData = variantSelects.getVariantData();
          if (!variantData) return;

          currentVariant = variantData.find((v) => v.id == variantId);
        }

        if (!currentVariant) return;

        // Update button based on variant availability
        if (!currentVariant.available) {
          // Variant is not available
          this.submitButton.setAttribute('sold-out', 'true');

          const buttonTextSpan = this.submitButton.querySelector('span');
          if (buttonTextSpan) {
            buttonTextSpan.textContent = window.variantStrings.notify || 'OUT OF STOCK - NOTIFY ME';
          }

          // Add Klaviyo BIS trigger class
          this.submitButton.classList.add('klaviyo-bis-trigger');

          // Change button type to prevent form submission
          this.submitButton.setAttribute('type', 'button');

          // Update sticky ATC button text if it exists
          if (this.stickyATC) {
            const stickyButtonTextSpan = this.stickyATC.querySelector('span');
            if (stickyButtonTextSpan) {
              stickyButtonTextSpan.textContent = window.variantStrings.notify || 'OUT OF STOCK - NOTIFY ME';
            }
            // Keep scroll attribute true instead of triggering BIS popup directly
            this.stickyATC.setAttribute('scroll', 'true');
          }
        } else {
          // Variant is available
          this.submitButton.removeAttribute('sold-out');
          this.submitButton.classList.remove('klaviyo-bis-trigger');

          // Reset button to submit type
          this.submitButton.setAttribute('type', 'submit');

          const buttonTextSpan = this.submitButton.querySelector('span');
          if (buttonTextSpan) {
            if (this.submitButton.hasAttribute('preorder')) {
              buttonTextSpan.textContent = window.variantStrings.preOrder || 'Pre-Order';
            } else {
              buttonTextSpan.textContent = window.variantStrings.addToCart || 'Add to Cart';
            }
          }

          // Update sticky ATC button text if it exists
          if (this.stickyATC) {
            const stickyButtonTextSpan = this.stickyATC.querySelector('span');
            if (stickyButtonTextSpan) {
              if (this.submitButton.hasAttribute('preorder')) {
                stickyButtonTextSpan.textContent = window.variantStrings.preOrder || 'Pre-Order';
              } else {
                stickyButtonTextSpan.textContent = this.originalStickyButtonText;
              }
            }
            // Only remove scroll attribute if size is selected
            if (this.submitButton.getAttribute('select-size') === 'false') {
              this.stickyATC.removeAttribute('scroll');
            }
          }
        }
      }

      onChangeHandler(evt) {
        if (this.sizeSelect) {
          //check if there is a size selected.
          const radios = this.sizeSelect.querySelectorAll('input[type="radio"]');
          let hasValue = false;
          radios.forEach((element) => {
            if (element.checked) hasValue = true;
          });

          if (hasValue) {
            this.message.textContent = '';

            this.submitButton.setAttribute('select-size', 'false');

            if (this.stickyATC) {
              this.stickyATC.removeAttribute('scroll');
            }
          } else {
            // check if the change is
            if (evt.target.getAttribute('name') !== 'Color-Select') {
              this.message.textContent = window.variantStrings.selectVariant;
              this.submitButton.setAttribute('select-size', 'true');
            }
            if (this.stickyATC) {
              this.stickyATC.setAttribute('scroll', 'true');
            }
          }
        }

        // If this is a variant change, update button state
        if (evt.target.name === 'id' || evt.target.classList.contains('variant-radio')) {
          this.updateButtonForVariantAvailability();
        }
      }

      onSubmitHandler(evt) {
        evt.preventDefault();
        if (this.submitButton.getAttribute('aria-disabled') === 'true') return;

        // Don't proceed if button is marked as sold out
        if (this.submitButton.getAttribute('sold-out') === 'true') return;

        if (this.submitButton.getAttribute('select-size') === 'true') {
          if (this.sizeSelect) {
            //check if there is a size selected.
            const radios = this.sizeSelect.querySelectorAll('input[type="radio"]');
            let hasValue = false;

            radios.forEach((element) => {
              if (element.checked) hasValue = true;
            });

            if (hasValue && this.oneSize) {
              this.message.textContent = '';

              this.submitButton.setAttribute('select-size', 'false');

              if (this.stickyATC) {
                this.stickyATC.removeAttribute('scroll');
              }
            } else {
              this.message.textContent = window.variantStrings.selectVariant;

              this.submitButton.setAttribute('select-size', 'true');

              if (this.stickyATC) {
                this.stickyATC.setAttribute('scroll', 'true');
              }
            }
          }
          return;
        }

        this.handleErrorMessage();

        this.submitButton.setAttribute('aria-disabled', true);
        this.submitButton.classList.add('loading');

        const config = fetchConfig('javascript');
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        delete config.headers['Content-Type'];

        const formData = new FormData(this.form);
        if (this.cart) {
          formData.append(
            'sections',
            this.cart.getSectionsToRender().map((section) => section.id)
          );
          formData.append('sections_url', window.location.pathname);
          this.cart.setActiveElement(document.activeElement);
        }
        config.body = formData;

        fetch(`${routes.cart_add_url}`, config)
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              publish(PUB_SUB_EVENTS.cartError, {
                source: 'product-form',
                productVariantId: formData.get('id'),
                errors: response.description,
                message: response.message
              });
              this.handleErrorMessage(response.description);

              const soldOutMessage = this.submitButton.querySelector('.sold-out-message');
              if (!soldOutMessage) return;
              this.submitButton.setAttribute('aria-disabled', true);
              this.submitButton.querySelector('span').classList.add('hidden');
              soldOutMessage.classList.remove('hidden');
              this.error = true;
              return;
            } else if (!this.cart) {
              window.location = window.routes.cart_url;
              return;
            }

            if (!this.error)
              publish(PUB_SUB_EVENTS.cartUpdate, { source: 'product-form', productVariantId: formData.get('id') });
            this.error = false;
            const quickAddModal = this.closest('quick-add-modal');
            if (quickAddModal) {
              document.body.addEventListener(
                'modalClosed',
                () => {
                  setTimeout(() => {
                    this.cart.renderContents(response);
                  });
                },
                { once: true }
              );
              quickAddModal.hide(true);
            } else {
              if (this.cart.getAttribute('open') != 'true') {
                this.cart.trigger(this.cart);
              }
              this.cart.renderContents(response);
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.submitButton.classList.remove('loading');
            if (this.cart && this.cart.classList.contains('is-empty')) this.cart.classList.remove('is-empty');
            if (!this.error) this.submitButton.removeAttribute('aria-disabled');
          });
      }

      handleErrorMessage(errorMessage = false) {
        if (this.hideErrors) return;

        this.errorMessageWrapper =
          this.errorMessageWrapper || this.querySelector('.product-form__error-message-wrapper');
        if (!this.errorMessageWrapper) return;
        this.errorMessage = this.errorMessage || this.errorMessageWrapper.querySelector('.product-form__error-message');

        this.errorMessageWrapper.toggleAttribute('hidden', !errorMessage);

        if (errorMessage) {
          this.errorMessage.textContent = errorMessage;
        }
      }
    }
  );
}
